import "./App.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { ReactComponent as CheckMark } from "./assests/check-mark-svgrepo-com.svg";
import { ReactComponent as ErrorMark } from "./assests/exclamation-mark-inside-a-circle-svgrepo-com.svg";
function App() {
  const [checking, setChecking] = useState(true);
  const [error, setError] = useState(false);
  const checkStatus = async () => {
    let tries = 0;
    while (true) {
      try {
        await axios.head("https://www.9amoon.com/");
        break;
      } catch (err) {
        if (tries++ > 2) {
          setError(true);
          break;
        }
      }
    }

    setChecking(false);
  };
  useEffect(() => {
    checkStatus();
  }, []);
  const config = {};
  return (
    <div className="App">
      <h1
        style={{
          color: "#0099cc",
          fontWeight: 700,
          marginTop: 0,
          marginBottom: 50,
          fontSize: 75,
        }}
        className="ordable-header"
      >
        {config.language === "arabic" ? (
          <>
            <span>/</span>
            <span>اوردبل</span>
          </>
        ) : (
          "ordable/"
        )}
      </h1>
      <h4
        style={{
          position: "relative",
          top: -45,
          fontWeight: "bold",
          color: "#474444",
          fontSize: 16,
        }}
        className="simplified"
      >
        {config.language === "arabic"
          ? "الحل الامثل للمتاجر الالكترونية"
          : "online selling, simplified"}
      </h4>

      <div>
        {checking ? (
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : error ? (
          <span style={{ fontSize: 26, color: "red" }}>
            Servers are down
            {/* <ErrorMark
              width={25}
              height={25}
              fill={"red"}
              style={{
                position: "relative",
                top: 5,
                marginRight: 5,
                marginLeft: 5,
              }}
            /> */}
          </span>
        ) : (
          <span style={{ fontSize: 26, color: "green" }}>
            All servers are up and running
            {/* <CheckMark
              width={25}
              height={25}
              fill={"green"}
              style={{
                position: "relative",
                top: 5,
                marginRight: 5,
                marginLeft: 5,
                fontSize: 26,
              }}
            /> */}
          </span>
        )}
      </div>
    </div>
  );
}

export default App;
